<template>
  <section class="mb-16 pb-5">
    <!-- Migas de pan -->
    <v-row>
      <v-col cols="12">
        <v-breadcrumbs class="px-0" :items="breadcrumbs" divider="/" />
      </v-col>
    </v-row>
    <!-- Título -->
    <v-row>
      <v-col cols="12">
        <p class="text-h6 secondary--text">Configurar subasta inversa</p>
      </v-col>
    </v-row>
    <!-- Calendarios -->
    <v-row class="row-calendar">
      <v-col cols="4">
        <vc-date-picker
          class="py-0 px-0"
          v-model="fecha_inicio_subasta"
          mode="datetime"
          locale="es"
          :popover="config_calendar"
        >
          <template v-slot="{ inputEvents }">
            <v-text-field
              class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
              label="Fecha y hora de inicio*"
              prepend-inner-icon="mdi-calendar"
              :value="getFechaInicioSubasta"
              v-on="inputEvents"
              outlined
              disabled
            />
          </template>
        </vc-date-picker>
      </v-col>
      <v-col cols="4">
        <vc-date-picker
          v-model="fecha_fin_subasta"
          mode="datetime"
          locale="es"
          :popover="config_calendar"
        >
          <template v-slot="{ inputEvents }">
            <v-text-field
              class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
              label="Fecha y hora de fin*"
              prepend-inner-icon="mdi-calendar"
              disabled
              :value="getFechaFinSubasta"
              v-on="inputEvents"
              outlined
            />
          </template>
        </vc-date-picker>
      </v-col>
    </v-row>
    <!-- Botón agregar sala -->
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          outlined
          label="Nombre de la sala*"
          v-model="nombreSala"
          clearable
          class="d-inline"
          :error-messages="errorNombreSala"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-btn class="d-inline secondary" @click="enviarFormulario"> {{ accionFormulario }} sala</v-btn>
      </v-col>
    </v-row>

    <!-- Tabla de salas -->
    <v-row>
      <v-col cols="12">
        <data-table-component
          :headers="headers"
          :items="salas"
          :show_actions="true"
          :actions="actions"
          @paginar="paginate"
          v-models:select="filters.per_page"
          v-models:pagina="filters.page"
          :total_registros="filters.total_rows"
          @show="irConfiguracionSala"
          @edit="modoEditar"
          @delete="confirmarEliminiacion"
        >
        </data-table-component>
      </v-col>
    </v-row>

    <v-row class="pt-6 mx-2">
      <v-btn outlined color="secondary" @click="$router.back()">
        Volver
      </v-btn>
    </v-row>

    <ConfirmationDialogComponent
      :show="modalEliminar"
      title="¿Desea remover la sala seleccionada ?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="modalEliminar = false"
      @confirm="eliminarSala()"
    />
  </section>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
export default {
  name: "configuracionSubastaInversaView",
  components: {
    DataTableComponent,
    ConfirmationDialogComponent,
  },
  data: () => ({
    processId: null,
    fecha_inicio_subasta: null,
    fecha_fin_subasta: null,
    nombreSala: null,
    editar: false,
    modalEliminar: false,
    filters: {
      page: 1,
      per_page: 10,
      total_rows: 1,
    },
    // Configuración del calendario
    config_calendar: {
      visibility: "click",
    },
    breadcrumbs: [
      {
        text: "Procesos",
        disabled: false,
        href: "#",
      },
      {
        text: "P001-2022",
        disabled: false,
        href: "#",
      },
      {
        text: "Subasta inversa",
        disabled: true,
        href: "#",
      },
    ],
    // Tabla
    headers: [
      {
        text: "Nombre",
        value: "nombre",
        sortable: true,
      },
      {
        text: "Acciones",
        value: "actions",

        sortable: false,
        align: "center",
      },
    ],
    actions: [
      {
        tooltip: {
          text: "Ver",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-eye",
        },
        eventName: "show",
      },
      {
        tooltip: {
          text: "Eliminar",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-delete",
        },
        eventName: "delete",
      },
    ],
  }),

  validations: {
    nombreSala: { required }
  },

  methods: {
    ...mapActions("etapaSubastaInversaStore", [
      'getSalasConfiguracionSubasta',
      'postSalasConfiguracionSubasta',
      'putSalasConfiguracionSubasta',
      'deleteSalaConfiguracionSubasta'
    ]),

    async getSalas () {
      const data = {
        id_proceso: this.processId,
        filters: this.filters
      }
      const response = await this.getSalasConfiguracionSubasta(data)
      this.fecha_inicio_subasta = response?.data?.fecha_hora_inicio
      this.fecha_fin_subasta = response?.data?.fecha_hora_fin
      this.setPaginacion(response?.headers)
    },

    async enviarFormulario () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      let response
      let message
      let data = { nombreSala: this.nombreSala }

      if(this.editar) {
        data.id_sala = this.salaId
        response = await this.putSalasConfiguracionSubasta(data)
        message = 'actualizada'
      } else {
        data.id_proceso = this.processId
        response = await this.postSalasConfiguracionSubasta(data)
        message = 'creada'
      }

      if(response?.status === 201 || response?.status === 200) {
        this.nombreSala = null
        this.$v.$reset()
        if (this.editar) {
          this.salaId = null
          this.editar = false
        }
        this.getSalas()
        this.temporalAlert({
          show: true,
          type: 'success',
          message: `Sala ${ message } correctamente`
        })
      }
    },

    modoEditar (item) {
      this.editar = true
      this.salaId = item.id
      this.nombreSala = item.nombre
    },

    confirmarEliminiacion(item) {
      this.salaId = item.id;
      this.modalEliminar = true;
    },

    async eliminarSala() {
      const response = await this.deleteSalaConfiguracionSubasta(this.salaId)
      if(response?.status === 204) {
        this.getSalas()
        this.salaId = null
        this.modalEliminar = false
        this.temporalAlert({
          show: true,
          type: 'success',
          message: 'Sala eliminada correctamente'
        })
      }
    },

    setPaginacion (payload) {
      this.filters.page = Number(payload.page)
      this.filters.per_page = Number(payload.per_page)
      this.filters.total_rows = Number(payload.total_rows)
    },

    async paginate(params) {
      const { cantidad_por_pagina, pagina} = params;
      this.filters.page = pagina,
          this.filters.per_page = cantidad_por_pagina
      await this.getSalas()
    },

    irConfiguracionSala (item) {
      this.$router.push({
        name: 'subasta-inversa-agregar-sala',
        params: { id: item.id }
      })
    }
  },

  computed: {
    ...mapState('etapaSubastaInversaStore', ['salas']),

    getFechaInicioSubasta() {
      return this.fecha_inicio_subasta
        ? this.$moment(this.fecha_inicio_subasta).format(
            "DD/MM/YYYY hh:mm A"
          )
        : "";
    },
    getFechaFinSubasta() {
      return this.fecha_fin_subasta
        ? this.$moment(this.fecha_fin_subasta).format("DD/MM/YYYY hh:mm A")
        : "";
    },

    errorNombreSala () {
      const errors = []
      if(!this.$v.nombreSala.$dirty) return errors
      !this.$v.nombreSala.required && errors.push('Nombre de sala es requerido')
      return errors
    },

    accionFormulario () {
      if(this.editar) return 'Actualizar'
      return 'Agregar'
    }
  },

  async created() {
    this.processId = this.$route.params.id_process
    await this.getSalas()
  }
};
</script>
<style lang="scss">
.row-calendar {
  .v-input {
    padding: 0px !important;
  }
}
</style>
